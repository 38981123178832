/* Import Bootstrap core */
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

/* Plugins */
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
@import "../../node_modules/font-awesome/css/font-awesome.css";
@import "../../node_modules/animate.css/animate.css";
@import "../../node_modules/jquery.mmenu/dist/jquery.mmenu.css";
@import "../../node_modules/bootstrap-vertical-tabs/bootstrap.vertical-tabs.min.css";

@import "layout.css";
@import "media.css";
@import "keyframe.css";
@import "page/about.css";
@import "page/service.css";
@import "page/inquiry.css";
@import "page/news.css";
@import "page/contact.css";
@import "page/modal.css";

/* custom properties */
:root {
	--mainColor: #181d62;
	--white: #fff;
	--black: #000;
	--navColor: #333;
	--greyColor: #d5d5d5;
	--lightgrey: #ececec;
	--footerColor: #26283c;
	--footertop: #40435f;

	/*social colours*/
	--fbColor: #3b5999;
	--lineColor: #00c300;
	--emailColor: #3c95da;

	--fontSize: 16px;
}

a {
	cursor: pointer;
  color: var(--mainColor);
  text-decoration: none;
  transition: all .3s;
}

a:--enter {
	text-decoration: none;
}

/* custom media queries */
@custom-media --only-medium-screen (min-width: 992px) and (max-width: 1200px);
@custom-media --navbar-layout-width (min-width: 1201px) and (max-width: 1349px);
@custom-media --xlarge-viewport (min-width: 1600px);
@custom-media --large-viewport 	(min-width: 992px);
@custom-media --medium-viewport (max-width: 991px);
@custom-media --small-viewport 	(max-width: 767px);
@custom-media --xsmall-viewport (max-width: 480px);


/* custom selectors */
@custom-selector :--heading h1, h2, h3, h4, h5, h6;

:--heading { 
	margin-top: 0;
}

@custom-selector :--list ul, ol;

:--list {
	padding: 0;
	margin: 0;
	list-style: none;
}

@custom-selector :--button button, .button;
@custom-selector :--enter :hover, :focus, :active, .active;

:--button {
	padding: 8px 12px !important;
  transition: all .3s;
}
:--button:--enter {
  
}

@custom-selector :--center-box .center-box;

:--center-box {
	width: 100%;
	display: table;
	margin: 0 auto;
}

@custom-selector :--center-box-inner .center-box-inner;

:--center-box-inner {
	display: table-cell;
	vertical-align: middle
}

/*page load*/
.no-js #loader { 
	display: none;  
}
.js #loader { 
	display: block; 
	position: absolute; 
	left: 100px; 
	top: 0; 
}
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 99999999999;
	background: url(../images/preloader.gif) center no-repeat #fff;
}

.reorder-box {
	display: flex;
  flex-flow: row wrap;
}

/* custom css */
.fa-fw {
	margin-right: 5px;
}
body {
	color: var(--navColor);
	font-size: calc(var(--fontSize) * 1);
	font-family: Arial, "微軟正黑體", "Microsoft JhengHei", Helvetica, sans-serif;
	padding: 0;
	margin: auto;
	line-height: calc(var(--fontSize) * 1.55);
	background: var(--white);
	-webkit-overflow-scrolling: touch;
}

body {
	overflow-x: hidden;
}

/* pagenavigation */

.pager li > a {
  font-size: calc(var(--fontSize) * .95);
  padding: 3px 14px;
  border-radius: 6px;
}

.pager li > a:--enter,
.pager li > a.active {
	color: var(--white);
	background: var(--mainColor);
}

.block-with-text {
  overflow: hidden;
  position: relative; 
  line-height: 1.2em;
  max-height: 3.6em; 
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.block-with-text:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}
.block-with-text:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

/*main-slider*/

/*#home-slider .item img {
	display: block;
	width: 100%;
	min-width: 100%;
}*/

#home-slider.owl-carousel {
	position: relative;
	height: 100%;
}

#home-slider .item {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
/*	position: absolute;
	top: 50%;
	left: 50%;*/
	width: auto;
	height: 100%;
/*	transform: translate(-50%, -50%);*/
}

#home-slider .customNavigation a {
	color: var(--white);
	font-size: calc(var(--fontSize) * 4);
	position: absolute;
	top: 50%;
	margin-top: -40px;
	z-index: 1;
}

#home-slider .customNavigation a.prev {
	left: 10%;
}

#home-slider .customNavigation a.next {
	right: 10%;
}

#home-slider .owl-nav.disabled + .owl-dots {
	position: absolute;
	width: 100%;
	bottom: 10px;
}

#home-slider .owl-dots .owl-dot {
	vertical-align: middle;
}

#home-slider .owl-dots .owl-dot span {
	width: 8px;
	height: 8px;
	background: var(--white);
}

#home-slider .owl-dots .owl-dot.active span, 
#home-slider .owl-dots .owl-dot:--enter span {
	background: transparent;
	border: 2px solid var(--white);
	width: 12px;
	height: 12px;
}

/*service*/
.service-home {
	width: 100%;
	padding-bottom: 50px;
}

#service-slider .service-item {
	text-align: center;
	padding: 30px 20px;
	border: 1px solid var(--mainColor);
	background: var(--white);
	transition: all .3s;
}

#service-slider svg {
	display: block;
	text-align: center;
	height: 100px;
	margin: 0 auto;
}

#service-slider .service-item h3 {
	font-size: calc(var(--fontSize) * 1.625);
	color: var(--mainColor);
	margin: 15px auto;
}

#service-slider .service-item .more-btn {
	display: inline-block;
	padding: 6px 10px;
	font-size: calc(var(--fontSize) * .95);
	font-weight: normal;
	color: var(--white);
	background: var(--mainColor);
}

#service-slider .service-item:--enter {
	background: var(--mainColor);
}

#service-slider .service-item:--enter svg {
	fill: var(--white);
}

#service-slider .service-item:--enter h3 {
	color: var(--white);
}

#service-slider .service-item:--enter .more-btn {
	color: var(--mainColor);
	background: var(--white);
	text-decoration: none;
}

#service-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: 50%;
  margin-top: -21px;
  width: 42px;
  height: 42px;
  line-height: 28px;
  color: var(--navColor);
  font-size: 30px;
  border-radius: 0;
  border: 2px solid #ccc;
  background: transparent;
  transition: all .3s;
}

#service-slider .owl-prev {
	left: -6%;
}

#service-slider .owl-next {
	right: -6%;
}

#service-slider .owl-nav [class*='owl-']:--enter {
	background: #ccc;
}

/*inquiry*/
.inquiry-home {
	width: 100%;
	position: relative;
}
.inquiry-info {
	padding: 20px;
	border: 1px solid var(--white);
	margin-bottom: 15px;
}
.inquiry-info h3 {
	color: var(--white);
	margin-bottom: 30px;
	white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.inquiry-info p {
	color: var(--white);
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}
.inquiry-member {
	display: table;
}
.inquiry-member .member-sexual {
	float: left;
	display: table-cell;
	vertical-align: middle;
	margin: 0 20px;
	max-width: 150px;
}
.inquiry-member .member-info {
	display: table-cell;
	vertical-align: middle;
	color: var(--white);
}
.inquiry-member .member-info .info {
	display: block;
	margin-bottom: 8px;
}
.inquiry-member .member-info .info span {
	margin-left: 5px;
}
.inquiry-member .member-info .info a.more-info {
	padding: 4px 12px;
	display: inline-block;
	color: var(--white);
	background: var(--mainColor);
}
.inquiry-member .member-info .info a.more-info:--enter {
	color: var(--mainColor);
	background: var(--white);
}

#inquiry-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: 50%;
  margin-top: -21px;
  width: 42px;
  height: 42px;
  line-height: 28px;
  color: var(--white);
  font-size: 30px;
  border-radius: 0;
  border: 2px solid #ccc;
  background: transparent;
  transition: all .3s;
}

#inquiry-slider .owl-prev {
	left: -6%;
}

#inquiry-slider .owl-next {
	right: -6%;
}

#inquiry-slider .owl-nav [class*='owl-']:--enter {
	color: var(--navColor);
	background: #ccc;
}

/*news*/
.news {
	padding-bottom: 50px;
}

.nav-tabs {
	border-bottom: 1px solid var(--white);
}

.custom-tab-content ul.custom-tab > li {
	margin-right: 5px;
}

.custom-tab-content ul.custom-tab > li > a {
	color: #666;
	padding: 6px 20px;
	font-size: calc(var(--fontSize) * 1.25);
	border-top: 4px solid #bbb;
	border-radius: 0;
	background: #e5e5e5;
	transition: all .3s;
}

.custom-tab-content ul.custom-tab > li.active > a,
.custom-tab-content ul.custom-tab > li:--enter > a {
	color: var(--white);
	border-top: 4px solid #181d62;
	background: #404596;
} 

.tab-content {
	padding: 30px;
	background: var(--white);
}

.tab-content ul.news-list > li {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #acacac;
}

.tab-content ul.news-list > li a {
	color: var(--navColor);
	display: block;
	width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tab-content ul.news-list > li a:--enter {
	color: var(--mainColor);
	text-decoration: none;
}

.tab-content ul.news-list > li i {
	margin-right: 6px;
}

.tab-content ul.news-list > li .post-date {
	position: absolute;
	color: #404596;
	right: 0;
	top: 0;
}

/*customer*/

#customer-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: 50%;
  margin-top: -21px;
  width: 42px;
  height: 42px;
  line-height: 28px;
  color: var(--white);
  font-size: 30px;
  border-radius: 0;
  border: 2px solid #ccc;
  background: transparent;
  transition: all .3s;
}

#customer-slider .owl-prev {
	left: -6%;
}

#customer-slider .owl-next {
	right: -6%;
}

#customer-slider .owl-nav [class*='owl-']:--enter {
	color: var(--navColor);
	background: #ccc;
}

.customer-item {
	display: table;
  width: 100%;
	text-align: center;
	padding: 30px;
	background: #3c3f6c;
}

.customer-item span {
	display: table-cell;
	vertical-align: middle;
	word-break: break-all;
	font-size: calc(var(--fontSize) * 1.25);
	line-height: calc(var(--fontSize) * 1.875);
	color: var(--white);
}

/*full width google map */
.map-frame {
	position: relative;
	width: 100%;
	height: 100%;
	padding-bottom: 30%;
	height: 0;
	overflow: hidden;
}

.map-frame iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.float-label-control {
  position: relative;
  margin-bottom: 1.5em;
  color: transparent;
}

.label-txt {
  position: relative;
  width: 100%;
  display: block;
	color: var(--navColor);
  text-align: right;
  line-height: 34px;
  padding-right: 10px;
}

.label-txt .must-fill {
	color: red;
  margin-left: 5px;
  vertical-align: middle;
}

.float-label-control input::-webkit-autofill,
.float-label-control textarea::-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
}

.float-label-control input,
.float-label-control textarea,
.float-label-control label {
  font-size: calc(var(--fontSize) * 1);
  box-shadow: none;
}

.float-label-control input,
.float-label-control textarea {
  display: block;
  width: 80%;
  padding: 0.1em 0em 1px 0em;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #aaa;
  outline: none;
  margin: 0px;
  box-shadow: none;
  background: none;
}

.float-label-control textarea {
  padding: 0.1em 0em 5px 0em;
}

.float-label-control label {
  position: absolute;
  font-weight: normal;
  top: -1.0em;
  left: 0.08em;
  color: #aaaaaa;
  z-index: -1;
  font-size: calc(var(--fontSize) * .85);
  animation: float-labels 300ms none ease-out;
  animation-play-state: running !important;
}

.float-label-control label.radio-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: 400;
	vertical-align: middle;
	cursor: pointer;
	font-size: calc(var(--fontSize) * 1);
	box-shadow: none;
	z-index: 0;
	top: 0;
	left: 0;
}

.float-label-control input.empty + label,
.float-label-control textarea.empty + label {
  top: 0.1em;
  font-size: calc(var(--fontSize) * 1.5);
  animation: none;
  -webkit-animation: none;
}

.float-label-control input:focus,
.float-label-control textarea:focus {
  box-shadow: none;
  border-bottom-width: 2px;
  padding-bottom: 0;
}

.float-label-control input:not(.empty) + label,
.float-label-control textarea:not(.empty) + label {
  z-index: 1;
}

.float-label-control input:not(.empty):focus + label,
.float-label-control textarea:not(.empty):focus + label {
  color: #aaaaaa;
}

.btn-primary {
  background-color: var(--mainColor);
  border-color: color(#181d62 tint(10%));
}

.btn-primary:--enter {
	background-color: color(#181d62 tint(10%));
}











