.news-banner {
	background-image: url(../images/banner/news.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 117px 0;
}

.news-page {
	position: relative;
	padding: 50px 15px 40px;
	background: #fefefe;
	box-shadow: -11px 0 15px -15px rgba(0,0,0,0.8), 11px 0 15px -15px rgba(0,0,0,0.8);
}

.news-page img {
	max-width: 100%;
	vertical-align: middle;
}

.news-content {
	position: relative;
	margin-bottom: 20px;
  border-right: 2px solid #e5e5e5;
  min-height: 400px;
}

.news-content.no-border {
	border-right: 0;
}

.news-page .tab-content {
	padding: 15px;
}

.news-content .nav-tabs {
  border-right: 1px solid var(--white);
  border-bottom: none;
}

.news-content .nav-tabs > li {
	margin-bottom: 5px;
}

.news-content .nav-tabs > li > a {
	color: #666;
	padding: 6px 20px;
	font-size: calc(var(--fontSize) * 1.25);
	border-left: 4px solid #bbb;
	border-radius: 0;
	background: #e5e5e5;
	transition: all .3s;
}

.news-content .nav-tabs > li.active > a,
.news-content .nav-tabs > li:--enter > a {
	color: var(--white);
	border-left: 4px solid #181d62;
	background: #404596;
} 

.news-content h3 {
	position: relative;
	color: var(--mainColor);
	margin-bottom: 25px;
}

.news-content h3::after {
	content:'';
	position: absolute;
	/*width: 60px;*/
	width: 100%;
	height: 2px;
	top: 36px;
  left: 0;
	background: #3f4575;
}

.news-content .news-date {
  position: absolute;
  right: 0px;
  display: inline-block;
  color: var(--white);
  text-align: center;
  padding: 6px 14px;
  border-radius: 6px 6px 0 0;
  background: #676c94;
}

.news-content h4 {
	color: var(--mainColor);
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 20px;
}

.news-content p {
	color: #666;
}

.news-share {
	margin: 20px 0;
}

.news-share ul li {
	display: inline-block;
	color: var(--white);
	margin-right: 6px;
	transition: all .3s;
}

.news-share ul li a {
	padding: 10px 10px;
	color: var(--navColor);
	font-size: calc(var(--fontSize) * .875);
	background: var(--greyColor);
	border-radius: 6px;
}

.news-share ul li a:--enter {
	color: var(--white);
}

.news-share ul li.line-share a:--enter {
	background: var(--lineColor);
}

.news-share ul li.fb-share a:--enter {
	background: var(--fbColor);
}

.news-box {
	position: relative;
	overflow: hidden;
	padding: 30px 20px;
	margin-bottom: 30px;
	border: 1px solid var(--mainColor);
	background: var(--white);
	z-index: 0;
	transition: all .3s;
}

.news-box .more-btn {
	/*float: right;*/
	display: inline-block;
	padding: 6px 10px;
	margin-top: 10px;
	font-size: calc(var(--fontSize) * .95);
	font-weight: normal;
	color: var(--white);
	background: var(--mainColor);
}

.news-box::after {
	content: "\f02d";
	font-size: calc(var(--fontSize) * 7.5);
  font-family: 'fontawesome';
  position: absolute;
	color: #f5f5f5;
	right: -20px;
	bottom: 15px;
	z-index: -1;
}

.news-box:--enter {
	background: var(--mainColor);
}

.news-box:--enter::after {
	color: color(#181d62 tint(5%));
}

.news-box:--enter h3 {
	color: var(--white);
}

.news-box:--enter h3::after {
	background: var(--white);
}

.news-box:--enter p {
	color: var(--white);
}

.news-box:--enter .more-btn {
	color: var(--mainColor);
	background: var(--white);
}

.news-right {
	position: relative;
}

.news-right h3 {
	color: var(--mainColor);
	margin-bottom: 30px;
}

.other-news #other-news-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: -50px;
  margin-top: -9px;
  width: 30px;
  height: 30px;
  line-height: 18px;
  padding: 0px 7px;
  color: var(--white);
  font-size: calc(var(--fontSize) * 1.5);
  border: 2px solid var(--mainColor);
  background: var(--mainColor);
}

.other-news #other-news-slider .owl-nav [class*='owl-']:--enter {
	color: var(--navColor);
	border-color: #ccc;
	background: #ccc;
}

.other-news #other-news-slider .owl-prev {
  left: inherit;
  right: 40px;
}

.other-news #other-news-slider .owl-next {
  right: 0;
}

.news-box h3 {
	white-space: nowrap; 
	width: 100%; 
	overflow: hidden;
	text-overflow: ellipsis; 
}


.news-content .back-list {
  position: relative;
  bottom: 0;
  left: 5px;
  margin-top: 30px;
}






