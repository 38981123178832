.about-banner {
	background-image: url(../images/banner/about.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 117px 0;
}

.about {
	position: relative;
	padding-top: 30px;
	/*padding-bottom: 30px;*/
	background: #fefefe;
	box-shadow: -11px 0 15px -15px rgba(0,0,0,0.8), 11px 0 15px -15px rgba(0,0,0,0.8);
}

.about img {
	max-width: 100%;
	vertical-align: middle;
}

.about-lawyer {
	padding: 20px;
}

.about-lawyer h3 {
	/*font-size: calc(var(--fontSize) * 1.625);*/
	color: var(--mainColor);
	border-bottom: 2px solid var(--mainColor);
	padding: 0 5px;
	margin-bottom: 25px;
	padding-bottom: 10px;
}

.about-lawyer h3 span {
	font-size: calc(var(--fontSize) * 1.375);
	margin-left: 3px;
	color: var(--footerColor);
}

.about-lawyer-info {
	padding: 0 5px;
	margin-bottom: 20px;
}

.about-lawyer-info h4 {
	font-size: calc(var(--fontSize) * 1);
	font-weight: 600;
	color: var(--black);
}

.about-lawyer-info ul {
	font-size: calc(var(--fontSize) * 1);
	padding-left: 20px;
	color: var(--navColor);
}

.about-lawyer-info ul li {
	padding-left: calc(var(--fontSize) * 1);
}

.about-lawyer-info ul li::before {
	content: "\f192";
	font-size: calc(var(--fontSize) * 0.82);
  font-family: 'fontawesome';
  display: inline-block;
  vertical-align: bottom;
  margin-left: -calc(var(--fontSize) * 1.3);
  width: calc(var(--fontSize) * 1.3);
}

.about-lawyer-pic {
	padding: 10px 0;
	text-align: center;
}

.about-lawyer-pic img {
	margin-bottom: 20px;
}

.overview {
	padding: 20px;
}

.overview h3 {
	color: var(--mainColor);
	margin-bottom: 20px;
}

.overview-content {
	/*border-top: 1px solid #ddd;*/
}

.customer-content {
  padding: 20px 0;
  background: var(--lightgrey);
}

.customer-content .customer-slider {
	padding: 10px;
}

.customer-content #customer-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: -60px;
  margin-top: -9px;
  width: 32px;
  height: 32px;
  line-height: 18px;
  padding: 2px 7px;
  color: var(--white);
  font-size: calc(var(--fontSize) * 1.5);
  border: 2px solid var(--mainColor);
  background: var(--mainColor);
}

.customer-content #customer-slider .owl-nav [class*='owl-']:--enter {
	color: var(--navColor);
	border-color: #ccc;
	background: #ccc;
}

.customer-content #customer-slider .owl-prev {
  left: inherit;
  right: 40px;
}

.customer-content #customer-slider .owl-next {
  right: 0;
}






