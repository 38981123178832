@media (--navbar-layout-width) {
	.logo {
		padding: 10px 15px;
	}
	.logo img {
    max-width: 280px;
	}
	#nav > li > a {
		padding: 7px 22px;
	}
	.header-right {
		margin-right: 20px;
	}
}
@media (--only-medium-screen) {
	.service-slider,
	.inquiry-slider,
	.customer-slider {
		padding: 0 30px;
	}
	.logo {
		padding: 10px 30px;
	}
	.header-right {
		display: none;
	}
	.header-top {
		display: block;
		padding: 0 30px;
		background: #eee;
		border-bottom:1px solid #ddd;
	}
	.header-top .social-top {
		margin-right: 5px;
	}
	.header-top .social-top,
	.header-top .contact-info {
		margin: 5px 0;
		float: right;
		display: inline-block;
	}    
	.header-top .contact-info {
		line-height: 27px;
	}
	.header-top .contact-info ul li {
		display: inline-block;
	}
	.about-lawyer {
		padding: 20px 0;
	}
	
}

@media (--large-viewport) {
	.container {
		padding-left: 50px;
		padding-right: 50px;
	}
	#home-slider .owl-item {
		height: 460px;
	}
}

@media (--xlarge-viewport) {
	#home-slider .owl-item {
		height: 660px;
	}
}

@media (--medium-viewport) {
	#home-slider .owl-item {
		height: 360px;
	}
	#service-slider .owl-nav [class*='owl-'],
	#inquiry-slider .owl-nav [class*='owl-'],
	#customer-slider .owl-nav [class*='owl-'] {
		position: relative;
		margin-top: 10px;
	}
	#service-slider .owl-prev,
	#inquiry-slider .owl-prev,
	#customer-slider .owl-prev  {
		left: 0;
	}
	#service-slider .owl-next,
	#inquiry-slider .owl-next,
	#customer-slider .owl-next {
		right: 0;
	}
	.mmenu,.mobile-call {
		display: block;
	}
	nav {
		display: flex;
	}
	.navbar {
		display: none;
	}
	.logo {
		float: none;
		text-align: center;
		display: block;
		padding: 5px;
	}
	.logo img {
		max-width: 250px;
	}
	.header {
    width: 100%;
    height: 92px;
	}
	#hamburger {
    position: absolute;
    font-size: calc(var(--fontSize) * 1.875);
    top: 50%;
    margin-top: -15px;
    color: var(--mainColor);
	}
	.mobile-call {
		position: absolute;
		font-size: calc(var(--fontSize) * 1.875);
		top: 50%;
		right: 10px;
/*		padding: 8px 12px;*/
/*		border-radius: 8px;*/
		margin-top: -15px;
		color: var(--mainColor);
/*		background: var(--mainColor);*/
	}
	.about-lawyer, .about-lawyer-pic {
		padding: 20px 0;
	}
	.overview {
    padding: 15px 5px;
	}
	.back-list {
	  position: relative;
	  bottom: 0;
	  left: 0;
	  margin: 30px 0;
	}
	.news-content {
		border-right: 0;
		min-height: 0;
	}
	.news-content .nav-tabs {
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
	}
	.news-content .nav-tabs > li {
		float: none;
		display: inline-block;
		z-index: 2;
		margin-right: 5px;
		margin-bottom: 0;
	}
	.news-content .nav-tabs > li > a {
		border-left: 0;
		border-top: 4px solid #bbb;
	}
	.news-content .nav-tabs > li.active > a,
	.news-content .nav-tabs > li:--enter > a {
		border-left: 0;
		border-top: 4px solid #181d62;
	} 
	.modal-dialog {
    width: 700px;
	}
}

@media (--small-viewport) {
	.service {
    order: 2;
    width: 100%;
    border-bottom: 2px solid var(--white);
    padding-bottom: 40px;
  }
  .inquiry {
    order: 1;
    width: 100%;
  }
  .news {
  	padding-top: 10px;
    border-top: 2px solid #cbcbcb;
  }
	#home-slider .owl-item {
		height: 360px;
	}
	.customNavigation {
		display: none;
	}
	.custom-tab-content ul.custom-tab {
	  white-space: nowrap;
	  width: 100%;
	  overflow-y: hidden;
	  overflow-x: auto;
	}
	.custom-tab-content ul.custom-tab > li {
    float: none;
    display: inline-block;
    z-index: 2;
	}
	.tab-content ul.news-list > li a {
		width: 100%;
	}
	.tab-content ul.news-list > li .post-date {
    position: relative;
    display: block;
    text-align: right;
	}
	.map-frame {
    padding-bottom: 55%;
	}
	.about-lawyer {
		padding: 0;
	}
	.about-lawyer-pic {
		padding: 0 20px;
	}
	.lawyer-info {
    order: 2;
    width: 100%;
  }
  .lawyer-pic {
    order: 1;
    width: 100%;
  }
  .label-txt {
	  text-align: left;
	}
	.float-label-control input:not(.empty) + label, 
	.float-label-control textarea:not(.empty) + label {
		display: none;
	}
	.float-label-control input, 
	.float-label-control textarea {
		width: 100%;
	}
	.verify {
		text-align: left;
	}
	.button-group {
		text-align: left;
		margin-left: 15px;
	}
	.float-label-control input.verification {
	  width: 100%;
	}
	.modal:before {
    height: 0;
	}
	.modal .inquiry-modal-content {
		padding: 0;
		margin-bottom: 15px;
	}
	.modal-dialog {
    width: 95%;
    margin: 15px;
	}
	.modal-footer .back-list {
    margin: 0;
	}	
}

@media (--xsmall-viewport) {
	.logo img {
		max-width: 230px;
	}
	#home-slider .owl-item {
		height: 260px;
	}
	.customNavigation {
		display: none;
	}
	.section-title {
		margin: 30px auto;
	}
	.sep-line {
    text-align: left;
    padding-left: 15px;
	}
	.sep-line-inner h2 {
		font-size: calc(var(--fontSize) * 1.5625);
		padding: 10px 15px;
		letter-spacing: 1px;
	}
	.more-action .more {
		padding: 8px 8px 8px 8px;
		font-size: calc(var(--fontSize) * 1);
	}
	.more-action .arrow {
    width: 33px;
    height: 33px;
    left: -32px;
	}
	.more-action .arrow .inner {
    width: 33px;
    height: 33px;
    left: 60px;
    margin-left: -37px;
	}
	/*.more-action .more span {
		display: none;
	}*/
	.map-frame {
    padding-bottom: 75%;
	}
	.inquiry-member .member-sexual {
    margin: 0 10px;
    max-width: 100px;
	}
	.customer-content .customer-slider {
		padding: 0;
	}
	.inquiry-content > div {
		padding-left: 0;
		padding-right: 0;
	}
	.modal-dialog {
    width: 90%;
	}
	.news-content .news-date {
	  padding: 6px 8px;
	  font-size: 14px;
	}
	.news-share ul li {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
	}
	.news-share ul li a {
    display: block;
	}
}

