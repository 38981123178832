.inquiry-banner {
	background-image: url(../images/banner/inquiry.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 117px 0;
}

.inquiry {
	position: relative;
	padding: 50px 15px 40px;
	background: #fefefe;
	box-shadow: -11px 0 15px -15px rgba(0,0,0,0.8), 11px 0 15px -15px rgba(0,0,0,0.8);
}

.inquiry img {
	max-width: 100%;
	vertical-align: middle;
}

.inquiry-content h4 {
	position: relative;
	text-align: center;
	color: var(--mainColor);
	line-height: 1.4;
	margin-bottom: 15px;
}

.submit-form {
	padding: 15px;
	padding-top: 30px;
}

.inquiry-form {
	position: relative;
}

.inquiry-form::before {
	content:'';
	position: absolute;
	width: 60px;
	height: 2px;
	top: 0;
  left: 50%;
  margin-left: -30px;
	background: var(--mainColor);
}

.verify {
	text-align: right;
	padding: 5px 0;
}

.verify i {
	margin: 0 8px;
	font-size: calc(var(--fontSize) * 1.25);
	vertical-align: middle;
}

.float-label-control input.verification {
  width: 87%;
}

.button-group {
	max-width: 85%;
	text-align: right;
}

.button-group button {
	margin-right: 10px;
}

.inquiry-content .service-box {
	background-image: url(../images/fixedbg.jpg);
	background-position: 50% 0%;
	background-repeat: no-repeat;
	border: none;
	transition: all .3s;
}

.inquiry-content .service-box:after {
	content: "\f0e6";
	color: #5e76a0;
}







