/*header*/
.header {
	position: relative;
	background: var(--white);
}

/*.header::after {
	content: "";
	position: absolute;
	z-index: 2;
	bottom: 0px;
	left: 0;
	width: 100%;
	height: 1px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, .3);
}*/

.logo {
	float: left;
	display: inline-block;
	padding: 10px 40px;
}

.logo img{
	max-width: 300px;
}

.mmenu,.mobile-call {
	display: none;
}

.mm-menu.mm-offcanvas {
	z-index: 10;
}

#mm-blocker {
	background: #030201;
	background: rgba(3, 2, 1, 0.4);
}

.mm-listview > li > a, 
.mm-listview > li > span {
  font-size: calc(var(--fontSize) * 1);
  padding: 15px 10px 15px 20px;
}

.mm-listview > li:--enter > a,
.mm-listview > li.active > a {
	color: var(--white);
	background: var(--mainColor);
}

.mm-next:after, 
.mm-prev:before {
  width: 10px;
  height: 10px;
}

.mm-menu .mm-listview > li:--enter .mm-next:after,
.mm-menu .mm-listview > li.active .mm-next:after {
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}

.mmenu-social {
	padding: 15px 0;
}

.social-media-wrap {
	text-align: center;
	margin-bottom: 5px;
}

.social-media-wrap a img {
	width: 40px;
	height: 40px;
	margin: 0 3px;
}

.contact-info-wrap div {
	font-size: calc(var(--fontSize) * 1);
	padding: 7px 15px;
}

.contact-info-wrap div i {
	width: 30px;
	text-align: center;
}

.navbar {
	float: left;
	padding: 0;
	border-radius: 0;
	margin-bottom: 0;
	border: none;
}

#nav {
	float: left;
	margin-right: 25px;
}

#nav > li {
	float: left;
	padding: 44px 0;
}

#nav > li > a {
	position: relative;
	color: var(--navColor);
	font-size: calc(var(--fontSize) * 1);
	font-weight: normal;
	padding: 7px 30px;
	transition: all .3s;
}

#nav > li > a:--enter {
	color: var(--white);
	background: var(--mainColor);
}

#nav > li.dropdown > a:--enter::before {
	content: "";
	position: absolute;
	display: block;
	left: 50%;
	width: 0;
	height: 0;
	margin-left: -8px;
	border-style: solid;
	top: 38px;
	border-color: var(--mainColor) transparent transparent transparent;
	border-width: 8px;
}

.dropdown-menu {
	top: 100px;
	left: 0px;
	padding: 0;
	margin: 0;
	text-align: center;
	min-width: 0;
	border-radius: 0;
	border: none;
	box-shadow: none;
	background: var(--mainColor);
}

.dropdown-menu > li {
	padding: 10px 4px;
	margin: 0 20px;
	border-bottom: 1px solid #04094e;
	border-top: 1px solid #292f82;
}

.dropdown-menu > li:first-child {
	border-top: none;
}

.dropdown-menu > li:last-child {
	border-bottom: none;
}

.dropdown-menu > li > a {
	color: var(--white);
	font-size: calc(var(--fontSize) * .95);
	font-weight: normal;
	padding: 0;
	line-height: normal;
	white-space: normal;
}

.dropdown-menu > li > a:--enter {
	text-decoration: none;
	color: #eee;
	background: transparent;
}

.dropdown-menu > span {
	content: "";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	border-style: solid;
	top: -16px;
	border-color: transparent transparent var(--mainColor) transparent;
	border-width: 8px;
}

.dropdown:--enter .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dropdown-toggle::after {
	display: none;
}

.header-top {
	display: none;
}

.header-right {
	float: right;
	/*margin-right: 50px;*/
}

.social-top {
	float: left;
	margin: 48px 0;
	margin-right: 5px;
}

.social-top ul li {
	float: left;
	margin-right: 6px;
}

.social-top ul li a {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 6px;
	/*background: var(--white);*/
}

.social-top ul li a:--enter {
	opacity: .9;
}

.social-top ul li a img {
	width: 100%;
}

.social-top ul li i {
	color: var(--white);
	font-size: calc(var(--fontSize) * 1.5);
}

.contact-info {
	float: left;
	margin: 36px 0;
}

.contact-info ul li {
	margin-bottom: 2px;
}

.contact-info ul li a {
	font-size: calc(var(--fontSize) * .9);
	color: var(--navColor);
}

.contact-info ul li i {
	width: 20px;
	text-align: center;
}

/*general*/
.whiteline {
	background-image: url(../images/whiteline-bg.jpg);
	background-repeat: repeat;
	background-position: 0 0;
}

.section-title {
	margin: 40px auto;
	text-align: center;
	position: relative;
}

.sep-line {
	position: relative;
	max-width: 900px;
	margin: 0 auto;
	text-align: center;
}

.sep-line::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 23px;
  margin-top: -1px;
  left: 0;
	background: var(--greyColor);
}

.sep-line-inner {
	position: relative;
}

.sep-line-inner h2 {
	margin: 0 auto;
	display: inline-block;
	padding: 10px 20px;
	letter-spacing: calc(var(--fontSize) * .2);
	background: url(../images/whiteline-bg.jpg) repeat;
	background-position: 0 0;
}

.sep-line-inner h2.textwhite {
	color: var(--white);
	/*background: url(../images/fixedbg.jpg) no-repeat;
	background-position: 0 0;*/
	background: #4f5a7d;
}

.more-action {
	position: absolute;
	top: 11px;
	right: 0;
}

.more-action .more {
	color: #000;
	position: relative;
	padding: 10px 20px 10px 10px;
	font-size: calc(var(--fontSize) * 1.125);
	background: var(--greyColor);
}

.more-action .more i {
	margin-right: 6px;
}

.more-action .arrow {
  position: absolute;
  top: 0px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  left: -40px;
}

.more-action .arrow .inner {
  width: 40px;
  height: 40px;
  left: 44px;
  bottom: 0;
  position: relative;
	transform: rotate(45deg);
	margin-left: -15px;
	background: var(--greyColor);
}

.block-bg {
  position: relative;
}

.fixedbg {
	background-attachment: fixed;
  background-image: url(../images/fixedbg.jpg);
  background-position: 50% 0%;
  background-repeat: repeat;
 	padding-top: 20px;
	padding-bottom: 50px;
  /*position: absolute;*/
  width: 100%;
  /*min-width: 1170px;*/
  background-size: cover;
}

/* subpage inner-banner */
.inner-banner {
	/*float: left;*/
	position: relative;
	width: 100%;
	z-index: 2;
	min-height: 280px;
	text-align: center;
  text-shadow: 2px 1px 4px #333;
}
.inner-banner h2 {
	font-size: calc(var(--fontSize) * 2.125);
	color: var(--white);
}
.inner-banner .inner-breadcrumb li {
	display: inline-block;
	color: color(#cfb963 tint(20%));
	font-weight: 400;
}
.inner-banner .inner-breadcrumb li::before {
  color: var(--white);
  content: "\f101";
  font-family: 'fontawesome';
  font-size: calc(var(--fontSize) * 1.125);
  font-weight: normal;
  left: 0;
  line-height: normal;
  margin: 0 7px 0 5px;
}
.inner-banner .inner-breadcrumb li:first-child::before {
	content: "";
	margin: 0;
}
.inner-banner .inner-breadcrumb li a {
	color: var(--white);
	transition: all .3s;
}
.inner-banner .inner-breadcrumb li a:--enter {
	color: color(#cfb963 tint(20%));
}
.inner-banner img {
	max-width: 100%;
}

/*.service-slider,
.inquiry-slider,
.customer-slider {
	padding: 0 30px;
}*/

/*footer*/
footer {
	background: var(--footerColor);
}

.footer-top .col-md-4 {
	text-align: center;
	margin-bottom: 20px;
}

.footer-top {
	padding: 50px 0 40px;
	background: var(--footertop);
}

.foot-social-add {
	text-align: center;
	padding: 15px;
}

.social-add {
	width: 100%;
	display: table;
	margin-bottom: 15px;
}

.social-add span {
	width: 100%;
	display: table-cell;
	vertical-align: middle;
	font-size: calc(var(--fontSize) * 0.9375);
	line-height: calc(var(--fontSize) * 1.125);
	padding: 0 15px;
	height: 42px;
	border-radius: 5px;
	color: var(--white);
	background: var(--fbColor);
}

.social-add span.addline {
	background: var(--lineColor);
}

.foot-social-add img {
	width: 100%;
}

.right-contact {
	position: relative;
	text-align: left;
}

.right-contact h4 {
	color: var(--white);
	text-align: center;
	font-size: calc(var(--fontSize) * 1.5);
	font-weight: 600;
	margin-bottom: 15px;
}

.right-contact ul li {
	width: 100%;
	margin-bottom: 10px;
	display: inline-block;
	font-size: calc(var(--fontSize) * 0.9375);
	color: var(--white);
}

.right-contact ul li a {
	display: inline-block;
	font-size: calc(var(--fontSize) * 0.9375);
	color: var(--white);
}

.right-contact ul li a:--enter {
	color: var(--greyColor);
}

.right-contact ul li i {
	font-size: calc(var(--fontSize) * 1);
	line-height: calc(var(--fontSize) * 1.875);
	margin-right: 10px;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid var(--white);
}

.copyright {
	font-size: calc(var(--fontSize) * 0.875);
	padding: 30px 0 15px;
	text-align: center;
	color: var(--white);
}

.copyright i {
	margin: 0 3px;
}

.copyright a {
	font-size: calc(var(--fontSize) * 1.375);
  color: #cfb963;
  margin-left: 5px;
  vertical-align: bottom;
}

.copyright a:--enter {
	color: color(#cfb963 tint(20%));
}

.copyright span {
	font-size: calc(var(--fontSize) * 1.125);
	vertical-align: top;
}

.totop {
	position: relative;
}

.gotop {
	position: absolute;
	bottom: -5px;
	left: 50%;
	margin-left: -35px;
	width: 70px;
	color: #aaa;
	font-size: calc(var(--fontSize) * 1.25);
	cursor: pointer;
	text-align: center;
	border-radius: 6px 6px 0 0;
	transition: all .3s;
	background: var(--footerColor);
}

.gotop:--enter {
	font-size: calc(var(--fontSize) * 1.625);
	color: var(--white);
	bottom: 0;
}




