.service-banner {
	background-image: url(../images/banner/service.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 117px 0;
}

.service {
	position: relative;
	padding: 50px 15px 40px;
	background: #fefefe;
	box-shadow: -11px 0 15px -15px rgba(0,0,0,0.8), 11px 0 15px -15px rgba(0,0,0,0.8);
}

.service img {
	max-width: 100%;
	vertical-align: middle;
}

.service-content {
	position: relative;
	margin-bottom: 20px;
}

.service-content h3 {
	position: relative;
	color: var(--mainColor);
	margin-bottom: 35px;
}

.service-content h3::after {
	content:'';
	position: absolute;
	width: 60px;
	height: 2px;
	top: 36px;
  left: 0;
	background: var(--mainColor);
}

.service-content p {
	color: #666;
}

.service-box {
	position: relative;
	overflow: hidden;
	padding: 30px 20px;
	margin-bottom: 30px;
	border: 1px solid var(--mainColor);
	background: var(--white);
	z-index: 0;
	transition: all .3s;
}

.service-box .more-btn {
	/*float: right;*/
	display: inline-block;
	padding: 6px 10px;
	margin-top: 10px;
	font-size: calc(var(--fontSize) * .95);
	font-weight: normal;
	color: var(--white);
	background: var(--mainColor);
}

.service-box::after {
	content: "\f24e";
	font-size: calc(var(--fontSize) * 7.5);
  font-family: 'fontawesome';
  position: absolute;
	color: #f5f5f5;
	right: -20px;
	bottom: 15px;
	z-index: -1;
}

.service-box:--enter {
	background: var(--mainColor);
}

.service-box:--enter::after {
	color: color(#181d62 tint(5%));
}

.service-box:--enter h3 {
	color: var(--white);
}

.service-box:--enter h3::after {
	background: var(--white);
}

.service-box:--enter p {
	color: var(--white);
}

.service-box:--enter .more-btn {
	color: var(--mainColor);
	background: var(--white);
}

.service-right {
	position: relative;
}

.service-right h3 {
	color: var(--mainColor);
	margin-bottom: 30px;
}

.other-service #other-service-slider .owl-nav [class*='owl-'] {
  position: absolute;
  top: -50px;
  margin-top: -9px;
  width: 30px;
  height: 30px;
  line-height: 18px;
  padding: 0px 7px;
  color: var(--white);
  font-size: calc(var(--fontSize) * 1.5);
  border: 2px solid var(--mainColor);
  background: var(--mainColor);
}

.other-service #other-service-slider .owl-nav [class*='owl-']:--enter {
	color: var(--navColor);
	border-color: #ccc;
	background: #ccc;
}

.other-service #other-service-slider .owl-prev {
  left: inherit;
  right: 40px;
}

.other-service #other-service-slider .owl-next {
  right: 0;
}

.back-list {
  /*position: absolute;*/
  display: inline-block;
  /*bottom: 30px;
  left: 5px;*/
  margin-top: 30px;
  padding: 6px 12px;
  color: var(--white);
  background: var(--mainColor);
}

.back-list:--enter {
	color: var(--white);
  background: color(#181d62 tint(5%));
  margin-left: -5px;
}





