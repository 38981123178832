.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-body {
	padding: 30px 15px;
}

.modal-footer {
	text-align: left;
}

.modal-footer .back-list {
	position: relative;
	bottom: 0;
	left: 15px;
	margin-top: 0;
}

.modal-title {
	font-size: calc(var(--fontSize) * 1.375);
	line-height: 1.4;
	color: var(--mainColor);
	padding: 10px 0;
	max-width: 90%;
}

.modal .inquiry-modal-content {
	padding: 20px 0;
}

.modal .inquiry-member {
  display: block;
  padding: 20px;
  background: #ddd;
}

.modal .inquiry-member .member-sexual {
  float: none;
  display: block;
  margin: 0 20px 20px;
  max-width: 100%;
  text-align: center;
}

.modal .inquiry-member .member-info .info {
	color: var(--navColor);
}

.modal .close {
	top: 15px;
	right: 10px;
	position: absolute;
	font-size: calc(var(--fontSize) * 2.125);
}







