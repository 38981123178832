.contact-banner {
	background-image: url(../images/banner/contact.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 117px 0;
}

.contact {
	position: relative;
	padding: 50px 15px 40px;
	background: #fefefe;
	box-shadow: -11px 0 15px -15px rgba(0,0,0,0.8), 11px 0 15px -15px rgba(0,0,0,0.8);
}

.contact img {
	max-width: 100%;
	vertical-align: middle;
}

.contact-content {
	margin-bottom: 15px;
}

.contact-content:last-child {
	padding-top: 30px;
	border-top: 3px double #ccc;
}

.contact-content h4 {
  width: 90%;
  margin: 0 auto;
  border-bottom: 3px double #ccc;
  position: relative;
  text-align: center;
  color: var(--mainColor);
  line-height: 1.4;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.submit-form {
	padding: 15px;
	padding-top: 30px;
}

.contact-form {
	position: relative;
}

/*.contact-form::before {
	content:'';
	position: absolute;
	width: 60px;
	height: 2px;
	top: 0;
  left: 50%;
  margin-left: -30px;
	background: var(--mainColor);
}*/

.contact-right-info {
	margin: 20px 0;
	background: var(--lightgrey);
}

.contact .float-label-control input, 
.contact .float-label-control textarea {
	width: 100%;
}

.contact-info-list {
	padding: 0 15px;
}

.contact-info-list li {
	padding: 20px 0;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #ddd;
	transition: all .3s;
}

.contact-info-list li:first-child {
	border-top: none;
}

.contact-info-list li:last-child {
	border-bottom: none;
}

.contact-info-list li i {
	width: 30px;
	text-align: center;
}

.contact-info-list li, 
.contact-info-list li a {
	font-size: calc(var(--fontSize) * 1);
	color: var(--navColor);
}

.contact-info-list li:--enter a {
	color: var(--mainColor);
}










